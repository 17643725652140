import React, { Suspense, useEffect, useState } from 'react';
import Head from 'next/head';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { Container, Section } from '@components';
import {
  BrandCard,
  CategoryCard,
  RichCard,
  RichCardV2,
  SmallStoreCard,
} from '@components/cards';
import { TBrand, TProduct, TProductCategory, TStore } from '@components/types';
import {
  getPopularProducts,
  getTrendingProducts,
  getProductCategories,
} from '@api/product';
import { getProductBrands, getNewHomeGrownBrands } from '@api/brand';
import { Footer } from '@components/footer';
import { staticImageLoader } from '@core/utils';
import { CarouselContainer } from '@components/container/carousel';
import { SwiperSlide } from 'swiper/react';
import { getLaunchedStores, getNearestStores } from '@api/store';
import { getNewLaunchBrands, getNewLaunchProducts } from '@api/newlaunch';
import { Header } from '@components/header';

const Navbar = dynamic(
  () => import('../src/components/navbar').then((mod) => mod.Navbar),
  {
    ssr: false,
  }
);

interface Props {
  isMobile: boolean;
  productCategories: TProductCategory[];
  popularBrands: TBrand[];
  initialTrendingProducts: TProduct[];
  initialNewHomeGrownBrands: TBrand[];
  initialNewLaunchedBrands: TBrand[];
}

export const getServerSideProps: GetServerSideProps<Props> = async (
  context
) => {
  const UA = context.req.headers['user-agent'];
  const isMobile = Boolean(
    UA.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
  const [
    productCategories,
    popularBrands,
    initialNewHomeGrownBrands,
    initialNewLaunchedBrands,
    initialTrendingProducts,
  ] = await Promise.all([
    getProductCategories(null, true),
    getProductBrands(),
    getNewHomeGrownBrands(),
    getNewLaunchBrands(),
    getTrendingProducts(),
    getNewLaunchProducts(),
  ]);

  return {
    props: {
      isMobile,
      productCategories,
      popularBrands,
      initialTrendingProducts,
      initialNewHomeGrownBrands,
      initialNewLaunchedBrands,
    },
  };
};

const Home: React.FC<Props> = ({
  isMobile,
  productCategories,
  popularBrands,
  initialNewHomeGrownBrands,
  initialNewLaunchedBrands,
  initialTrendingProducts,
}: Props) => {
  const [popularProducts, setPopularProducts] = useState<TProduct[]>([]);
  const [trendingProducts, setTrendingProducts] = useState<TProduct[]>(
    initialTrendingProducts
  );
  const [newLaunchedStores, setNewLaunchedStores] = useState<TStore[]>([]);
  const [nearestStores, setNearestStores] = useState<TStore[]>([]);
  const [newLaunchedProducts, setNewLaunchedProducts] = useState<TProduct[]>(
    []
  );
  const [newLaunchedBrands, setNewLaunchedBrands] = useState<TBrand[]>(
    initialNewLaunchedBrands
  );
  const [newHomeGrownBrands, setNewHomeGrownBrands] = useState<TBrand[]>(
    initialNewHomeGrownBrands
  );

  const getAllNearestStores = (extend: boolean = true) => {
    const res: Promise<TStore[]> = getNearestStores(null, 0, 10, false);
    res.then((result) => {
      setNearestStores(result);
    });
    const resStore: Promise<TStore[]> = getLaunchedStores(
      null,
      0,
      isMobile ? 5 : 10
    );
    resStore.then((result) => {
      setNewLaunchedStores(result);
    });
  };

  const getAllNewLaunchProducts = () => {
    const res: Promise<TProduct[]> = getNewLaunchProducts();
    res.then((result) => {
      setNewLaunchedProducts(result);
    });
  };

  const getAllNewLaunchBrands = () => {
    const res: Promise<TBrand[]> = getNewLaunchBrands();
    res.then((result) => {
      setNewLaunchedBrands(result);
    });
  };

  const fetchNewHomeGrownBrands = () => {
    const res: Promise<TBrand[]> = getNewHomeGrownBrands();
    res.then((result) => {
      setNewHomeGrownBrands(result);
    });
  };

  useEffect(() => {
    getPopularProducts().then((res) => {
      setPopularProducts(res);
    });

    getTrendingProducts().then((res) => {
      setTrendingProducts(res);
    });
    getAllNearestStores();
    getAllNewLaunchProducts();
    getAllNewLaunchBrands();
    fetchNewHomeGrownBrands();
  }, []);

  return (
    <Container>
      <Head>
        <title>Online Shopping - Jhattse</title>
        <meta
          name="keywords"
          content="Local Stores,Local+Online,Online Stores,Shopping Sites,Local Services,Online Shopping,Jhattse"
        />
        <meta
          name="description"
          content="Discover products and services around me whether local or online. Find Stores and Product on Jhattse an online platform which provides product visiblity, best price discovering and all available deals and offers."
        />
        <meta property="og:title" content="Online Shopping - Jhattse" />
        <meta
          property="og:description"
          content="Discover products and services around you whether local or online. Jhattse provides product visiblity, best price discovering and all available deals and offers."
        />
        <meta
          name="og:image"
          content="https://cdn.jhattse.com/public/consumer/square-logo.png"
        />
        <meta property="og:url" content="https://jhattse.com" />
        <link rel="canonical" href="https://jhattse.com" />
      </Head>
      <div className="flex justify-center">
        <Header />
      </div>
      <div className="flex flex-col gap-6 mx-auto w-full max-w-[1240px] pt-4 pb-10 lt-sm:pb-20">
        <div className="flex flex-col justify-center gap-4 px-2">
          <div className="section select-none text-neutral-900">
            <CarouselContainer>
              <SwiperSlide style={{ width: 'auto' }} className="min-w-0">
                <div className="shrink-0 md:w-[400px] w-[300px] md:h-[216px] h-[162px]">
                  <Link href="/category/1/grocery-gourmet-foods">
                    <Image
                      loader={staticImageLoader}
                      width={isMobile ? '300' : '400'}
                      height={isMobile ? '162' : '216'}
                      className="flex items-center justify-center md:w-[400px] w-[300px] md:h-[216px] h-[162px] object-cover rounded-t-xl"
                      src="public/consumer/grocery_banner.webp"
                      alt="Grocery Products on Jhattse"
                      priority={true}
                    />
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }} className="min-w-0">
                <div className="shrink-0 md:w-[400px] w-[300px] md:h-[216px] h-[162px]">
                  <Link href="/category/2819/food">
                    <Image
                      loader={staticImageLoader}
                      src="public/consumer/food_banner.webp"
                      width={isMobile ? '300' : '400'}
                      height={isMobile ? '162' : '216'}
                      className="flex items-center justify-center md:w-[400px] w-[300px] md:h-[216px] h-[162px] object-cover rounded-t-xl"
                      alt="Food Restaurants on Jhattse"
                      priority={true}
                    />
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }} className="min-w-0">
                <div className="shrink-0 md:w-[400px] w-[300px] md:h-[216px] h-[162px]">
                  <Link href="/store">
                    <Image
                      loader={staticImageLoader}
                      width={isMobile ? '300' : '400'}
                      height={isMobile ? '162' : '216'}
                      className="flex items-center justify-center md:w-[400px] w-[300px] md:h-[216px] h-[162px] object-cover rounded-t-xl"
                      src="public/consumer/store_banner.webp"
                      alt="Local Stores on Jhattse"
                      priority={true}
                    />
                  </Link>
                </div>
              </SwiperSlide>
            </CarouselContainer>
          </div>
          <div className="section select-none text-neutral-900">
            <CarouselContainer>
              <SwiperSlide style={{ width: 'auto' }} className="min-w-0">
                <div className="shrink-0 md:w-[400px] w-[300px] md:h-[216px] h-[162px]">
                  <Link href="/category/900/electronics">
                    <Image
                      loader={staticImageLoader}
                      src="public/consumer/electronics_banner.webp"
                      width={isMobile ? '300' : '400'}
                      height={isMobile ? '162' : '216'}
                      className="flex items-center justify-center md:w-[400px] w-[300px] md:h-[216px] h-[162px] object-cover rounded-t-xl"
                      alt="Electronics & Computer on Jhattse"
                      priority={true}
                    />
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }} className="min-w-0">
                <div className="shrink-0 md:w-[400px] w-[300px] md:h-[216px] h-[162px]">
                  <Link href="/category/3521/books-stationery">
                    <Image
                      loader={staticImageLoader}
                      width={isMobile ? '300' : '400'}
                      height={isMobile ? '162' : '216'}
                      className="flex items-center justify-center md:w-[400px] w-[300px] md:h-[216px] h-[162px] object-cover rounded-t-xl"
                      src="public/consumer/books_banner.webp"
                      alt="Books & Stationary on Jhattse"
                      priority={true}
                    />
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }} className="min-w-0">
                <div className="shrink-0 md:w-[400px] w-[300px] md:h-[216px] h-[162px]">
                  <Link href="/category/1963/kitchen-tools">
                    <Image
                      loader={staticImageLoader}
                      width={isMobile ? '300' : '400'}
                      height={isMobile ? '162' : '216'}
                      className="flex items-center justify-center md:w-[400px] w-[300px] md:h-[216px] h-[162px] object-cover rounded-t-xl"
                      src="public/consumer/kitchen_tools_banner.webp"
                      alt="Kitchen Tools & Essentials on Jhattse"
                      priority={true}
                    />
                  </Link>
                </div>
              </SwiperSlide>
            </CarouselContainer>
          </div>
        </div>
        <Suspense>
          <Section
            title="Popular Categories"
            viewUrl="/category/"
            products={productCategories}
            isCarousel={true}
            element={CategoryCard}
            intent="productcategory"
            style="p-2 text-neutral-900"
            headStyle="md:text-xl text-lg py-1 font-semibold font-manrope"
            loading="lazy"
          />
        </Suspense>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-2">
          <div className="flex flex-col gap-2 justify-between w-full">
            <div className="bg-gradient-to-r from-brand_cerulean to-brand_cerulean/60 rounded-lg p-4">
              <Suspense>
                <Section
                  title="Newly Launched Brands"
                  products={newLaunchedBrands}
                  element={BrandCard}
                  isCarousel={true}
                  style="pb-2 text-neutral-900"
                  headStyle="md:text-xl text-lg py-1 font-semibold font-manrope"
                  loading="lazy"
                />
              </Suspense>
              <Link href="/brand/explore?ordering_on=new">
                <button
                  type="button"
                  className="text-sm md:text-base text-brand-500 font-manrope font-medium cursor-pointer"
                >
                  VIEW ALL
                </button>
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-2 justify-between w-full">
            <div className="bg-gradient-to-r from-green-200 to-blue-100 rounded-lg p-4">
              <Suspense>
                <Section
                  title="Homegrown Brands"
                  products={newHomeGrownBrands}
                  element={BrandCard}
                  isCarousel={true}
                  style="pb-2 text-neutral-900"
                  headStyle="md:text-xl text-lg py-1 font-semibold font-manrope"
                />
              </Suspense>
              <Link href={'/brand/homegrown'}>
                <div className="w-full flex items-center font-manrope">
                  <button className="font-medium md:text-base text-sm text-brand-500 font-manrope cursor-pointer">
                    VIEW ALL
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <Suspense>
          <div className="flex flex-col gap-2 justify-between px-2">
            <div className="bg-gradient-to-r from-new_lunch-200 to-new_lunch-100 rounded-lg p-4">
              <div className="flex flex-col font-manrope gap-2 pb-2 h-full">
                <h2 className="md:text-xl text-lg font-bold font-manrope">
                  Newly Launched Stores
                </h2>
                <CarouselContainer extraClasses="h-full">
                  {newLaunchedStores?.map((newLaunchedStore, index: number) => (
                    <SwiperSlide
                      style={{ width: 'auto' }}
                      className="min-w-0 h-full"
                      key={index}
                    >
                      <SmallStoreCard
                        store={newLaunchedStore}
                        hideRating={true}
                      />
                    </SwiperSlide>
                  ))}
                </CarouselContainer>
              </div>
              <div className="w-full flex items-center font-manrope">
                <Link href={'/store'}>
                  <button className="font-bold md:text-base text-sm text-brand-500 font-manrope cursor-pointer">
                    VIEW ALL
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Suspense>
        <div className="flex flex-col gap-2 justify-between px-2">
          <div className="bg-gradient-to-r from-brand_celeste to-brand_indigo rounded-lg p-4">
            <Suspense>
              <Section
                title="New Products"
                products={newLaunchedProducts}
                element={RichCard}
                isCarousel={true}
                style="pb-2 text-neutral-900"
                headStyle="md:text-xl text-lg py-1 font-semibold font-manrope"
                loading="lazy"
              />
            </Suspense>
            <Link href={'/product/trending'}>
              <div className="w-full flex items-center font-manrope">
                <button className="font-medium md:text-base text-sm text-brand-500 font-manrope cursor-pointer">
                  VIEW ALL
                </button>
              </div>
            </Link>
          </div>
        </div>
        <div className="px-2">
          <div className="flex flex-col gap-2 py-3">
            <h2 className="md:text-xl text-lg py-1 font-bold font-manrope">
              Nearest to You
            </h2>
            <Suspense>
              <CarouselContainer>
                {nearestStores?.map((store, index: number) => (
                  <SwiperSlide
                    style={{ width: 'auto' }}
                    className="min-w-0"
                    key={index}
                  >
                    <SmallStoreCard store={store} />
                  </SwiperSlide>
                ))}
              </CarouselContainer>
            </Suspense>
          </div>
        </div>
        <Suspense>
          <Section
            title="Trending Products"
            products={
              trendingProducts?.length > 0 ? trendingProducts : popularProducts
            }
            element={RichCardV2}
            isCarousel={true}
            style="p-2 text-neutral-900"
            headStyle="md:text-xl text-lg py-1 font-semibold font-manrope"
            loading="lazy"
          />
        </Suspense>
        <Section
          title="Explore Brands"
          viewUrl="/brand/explore"
          products={popularBrands}
          element={BrandCard}
          isCarousel={true}
          style="p-2 text-neutral-900"
          headStyle="md:text-xl text-lg py-1 font-semibold font-manrope"
          loading="lazy"
        />
        <Suspense>
          <Section
            title="Popular Products"
            viewUrl="/product/trending"
            products={popularProducts}
            element={RichCard}
            isCarousel={true}
            style="p-2 text-neutral-900"
            headStyle="md:text-xl text-lg py-1 font-semibold font-manrope"
            loading="lazy"
          />
        </Suspense>
        {!isMobile ? (
          <div className="p-2 relative hidden lg:block">
            <Link
              className="w-full h-full relative"
              href="https://business.jhattse.com/products/billing-software-for-brands"
            >
              <Image
                loader={staticImageLoader}
                className="w-full h-full object-contain"
                src="public/consumer/business_banner.webp"
                width="1544"
                height="268"
                loading="lazy"
                alt="Start selling on Jhattse"
                quality={100}
              />
            </Link>
          </div>
        ) : (
          <div className="rounded-xl relative p-2 md:hidden flex overflow-hidden">
            <Link
              href="https://business.jhattse.com/products/billing-software-for-brands"
              target="_blank"
            >
              <Image
                loader={staticImageLoader}
                className="h-full object-contain"
                src="public/consumer/business_banner_mobile.webp"
                width="368"
                height="220"
                loading="lazy"
                alt="Start selling on Jhattse"
                quality={100}
              />
            </Link>
          </div>
        )}
      </div>
      <Navbar />
      <Footer />
    </Container>
  );
};

export default Home;
